import React, { forwardRef } from "react";
import classnames from "classnames";
import styles from "./inputs.module.scss";

interface RadioTileProps extends React.ComponentPropsWithoutRef<"input"> {
  children?: React.ReactNode;
  valid?: boolean;
  invalid?: boolean;
  className?: string;
  inputClassName?: string;
}

// basic inputs with ref forwarding
export const RadioTile = forwardRef<HTMLInputElement, RadioTileProps>(
  (props, ref) => {
    const { children, valid, invalid, className, ...rest } = props;
    const classNamesWrapper = classnames(styles.tileWrapper, className);
    const classesBg = classnames(styles.tileBg);
    const classNamesInput = classnames(styles.tileInput);
    return (
      <label className={classNamesWrapper}>
        <input ref={ref} className={classNamesInput} type="radio" {...rest} />
        <span className={styles.tileLabel}>{children}</span>
        <span className={classesBg} />
      </label>
    );
  }
);

interface RadioInputProps extends React.ComponentPropsWithoutRef<"input"> {
  children?: React.ReactNode;
  valid?: boolean;
  invalid?: boolean;
  className?: string;
  inputClassName?: string;
  type?: string;
}

// basic inputs with ref forwarding
export const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  (props, ref) => {
    const {
      children,
      valid,
      invalid,
      className,
      inputClassName,
      type = "primary",
      ...rest
    } = props;
    const classNamesWrapper = classnames(
      styles["ef-input-w"],
      styles["ef-boolean"],
      valid && styles["-is-valid"],
      invalid && styles["-is-invalid"],
      className
    );
    const classNamesInput = classnames(
      styles["ef-boolean__input"],
      styles[`ef-boolean__input-${type}`],
      inputClassName
    );
    return (
      <label className={classNamesWrapper}>
        <input ref={ref} className={classNamesInput} type="radio" {...rest} />
        <span className={styles["ef-boolean__label"]}>{children}</span>
      </label>
    );
  }
);

interface CheckboxInputProps extends React.ComponentPropsWithoutRef<"input"> {
  children?: React.ReactNode;
  valid?: boolean;
  invalid?: boolean;
  className?: string;
  inputClassName?: string;
  type?: string;
}

export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  (props, ref) => {
    const { children, valid, invalid, className, inputClassName, ...rest } =
      props;
    const classNamesWrapper = classnames(
      "ef-input-w",
      "ef-boolean",
      valid && styles["-is-valid"],
      invalid && styles["-is-invalid"],
      className
    );

    const classNamesInput = classnames("ef-boolean__input", inputClassName);
    return (
      <label className={classNamesWrapper}>
        <input
          className={classNamesInput}
          type="checkbox"
          ref={ref}
          {...rest}
        />
        <span className="ef-boolean__element -checkbox" />
        <span className="ef-boolean__label">{children}</span>
      </label>
    );
  }
);

interface TextInputProps extends React.ComponentPropsWithoutRef<"input"> {
  valid?: boolean;
  invalid?: boolean;
  className?: string;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const { valid, invalid, className, ...rest } = props;
    const classNamesWrapper = classnames(
      "ef-input-w",
      `${valid ? "-is-valid" : ""}`,
      `${invalid ? "-is-invalid" : ""}`,
      className
    );

    return (
      <div className={classNamesWrapper}>
        <input ref={ref} className="ef-input" {...rest} />
      </div>
    );
  }
);

interface TextareaInputProps
  extends React.ComponentPropsWithoutRef<"textarea"> {
  valid?: boolean;
  invalid?: boolean;
  className?: string;
}

export const TextareaInput = forwardRef<
  HTMLTextAreaElement,
  TextareaInputProps
>((props, ref) => {
  const { valid, invalid, className, ...rest } = props;
  const classNamesWrapper = classnames(
    "ef-input-w",
    `${valid ? "-is-valid" : ""}`,
    `${invalid ? "-is-invalid" : ""}`,
    className
  );
  return (
    <div className={classNamesWrapper}>
      <textarea ref={ref} className="ef-input" {...rest} />
    </div>
  );
});

interface SelectInputProps extends React.ComponentPropsWithoutRef<"select"> {
  valid?: boolean;
  invalid?: boolean;
  className?: string;
}

export const SelectInput = forwardRef<HTMLSelectElement, SelectInputProps>(
  (props, ref) => {
    const { children, valid, invalid, className, ...rest } = props;
    const classNamesWrapper = classnames(
      "ef-input-w",
      "-select",
      valid ? "-is-valid" : "",
      invalid ? "-is-invalid" : "",
      className
    );
    return (
      <div className={classNamesWrapper}>
        <select ref={ref} className="ef-input" {...rest}>
          {children}
        </select>
      </div>
    );
  }
);

interface ValidationMessageProps {
  show?: boolean;
  children?: React.ReactNode;
}

export const ValidationMessage = ({
  children,
  show,
}: ValidationMessageProps) => {
  return (
    <p
      className="ef-text-caption u-mb-0 u-mt-xs"
      style={{
        visibility: show ? "visible" : "hidden",
      }}
    >
      {children}
    </p>
  );
};
